import { useLockBodyScroll } from "@uidotdev/usehooks";
import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import toast from "react-hot-toast";

const Schedule = ({ schedule, setSchedule }) => {
  useLockBodyScroll();

  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [projectType, setProjectType] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  const scheduleAppointment = (e) => {
    e.preventDefault();
    toast.loading("Sending...");

    // Your EmailJS services ID, template ID, and Public key
    const serviceId = process.env.REACT_APP_schedule_serviceId;
    const templateId = process.env.REACT_APP_schedule_templateId;
    const publicKey = process.env.REACT_APP_schedule_publicKey;

    // Create a new object that contains dynamic template params
    const templateParams = {
      from_name: fullName,
      phone: phone,
      from_email: email,
      projectType: projectType,
      date: date,
      time: time,
      to_name: "Chekwas Construction Company Ltd",
      reply_to: email,
    };

    // Send the email using EmailJS
    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        toast.dismiss();
        toast.success("Your request has been sent!");
        setFullName("");
        setPhone("");
        setEmail("");
        setProjectType("");
        setDate("");
        setTime("");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <div className="flex items-center justify-center text-sm w-full h-svh absolute bottom-0 z-40 overflow-scroll | bg-black bg-opacity-50">
      <div className="w-[90%] max-w-xl p-6 bg-[#fefefe]">
        <CloseSchedule schedule={schedule} setSchedule={setSchedule} />

        <p className="text-2xl font-medium w-[90%] mt-4">Schedule an Appointment with us</p>
        <hr className="my-6" />

        <form onSubmit={(e) => scheduleAppointment(e)}>
          <label className="block font-medium" htmlFor="">
            Full Name
          </label>
          <input
            className="mt-2 mb-8 border-black border-[0.5px] bg-transparent outline-none w-full p-3"
            type="text"
            placeholder="Surname first"
            required
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />

          <label className="block font-medium" htmlFor="">
            Phone Number
          </label>
          <input
            className="mt-2 mb-8 border-black border-[0.5px] bg-transparent outline-none w-full p-3"
            type="tel"
            placeholder="Valid phone number"
            required
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />

          <label className="block font-medium" htmlFor="">
            Email
          </label>
          <input
            className="mt-2 mb-8 border-black border-[0.5px] bg-transparent outline-none w-full p-3"
            type="email"
            placeholder="Valid email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <label className="block font-medium" htmlFor="">
            What project are you interested in?
          </label>
          <select
            className="mt-2 mb-8 border-black border-[0.5px] bg-transparent outline-none w-full p-3"
            name="projectType"
            id="projectType"
            required
            value={projectType}
            onChange={(e) => setProjectType(e.target.value)}
          >
            <option>Choose Options</option>
            <option value="residential">Residential</option>
            <option value="commercial">Commercial</option>
            <option value="land">Land</option>
          </select>

          <div className="flex space-x-4 w-full justify-evenly">
            <div className="w-full">
              <label className="block font-medium" htmlFor="">
                Pick a date
              </label>
              <input
                className="mt-2 mb-8 border-black border-[0.5px] bg-transparent outline-none w-full p-3"
                type="date"
                placeholder="DD/MM/YYYY"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
            <div className="w-full">
              <label className="block font-medium" htmlFor="">
                Fix time
              </label>
              <input
                className="mt-2 mb-8 border-black border-[0.5px] bg-transparent outline-none w-full p-3"
                type="time"
                placeholder="H:M"
                value={time}
                onChange={(e) => setTime(e.target.value)}
              />
            </div>
          </div>

          <input
            className="cursor-pointer font-semibold bg-[#c1892c] text-white px-3 py-4 transition duration-300 hover:bg-[#a27327]"
            type="submit"
            value={"Schedule Appointment"}
          />
        </form>
      </div>
    </div>
  );
};

export default Schedule;

export const CloseSchedule = ({ schedule, setSchedule }) => {
  return (
    <div
      onClick={() => setSchedule(false)}
      className="cursor-pointer float-right p-2 rounded-lg transition duration-300 | hover:bg-red-700"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_12_170)">
          <path
            d="M9.55795 8.98127L16.4663 2.07294C16.6028 1.91352 16.6741 1.70846 16.666 1.49873C16.6579 1.289 16.571 1.09005 16.4226 0.941639C16.2742 0.793226 16.0752 0.706282 15.8655 0.698181C15.6558 0.69008 15.4507 0.761419 15.2913 0.897941L8.38295 7.80627L1.47462 0.889608C1.3177 0.732688 1.10487 0.644531 0.882953 0.644531C0.661035 0.644531 0.448206 0.732688 0.291286 0.889608C0.134366 1.04653 0.0462098 1.25936 0.0462098 1.48127C0.0462098 1.70319 0.134366 1.91602 0.291286 2.07294L7.20795 8.98127L0.291286 15.8896C0.204051 15.9643 0.133201 16.0562 0.0831818 16.1596C0.0331624 16.263 0.0050539 16.3756 0.000620993 16.4904C-0.00381192 16.6052 0.015526 16.7196 0.0574214 16.8265C0.0993169 16.9335 0.162865 17.0306 0.244078 17.1118C0.32529 17.193 0.422413 17.2566 0.52935 17.2985C0.636288 17.3404 0.750732 17.3597 0.865498 17.3553C0.980263 17.3508 1.09287 17.3227 1.19626 17.2727C1.29965 17.2227 1.39158 17.1518 1.46629 17.0646L8.38295 10.1563L15.2913 17.0646C15.4507 17.2011 15.6558 17.2725 15.8655 17.2644C16.0752 17.2563 16.2742 17.1693 16.4226 17.0209C16.571 16.8725 16.6579 16.6735 16.666 16.4638C16.6741 16.2541 16.6028 16.049 16.4663 15.8896L9.55795 8.98127Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_12_170">
            <rect width="16.6667" height="16.7114" fill="white" transform="translate(0 0.644531)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
