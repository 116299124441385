import { useState, useEffect } from "react";

const Service = () => {
  const [service1, setService1] = useState(false);
  const [service2, setService2] = useState(false);
  const [service3, setService3] = useState(false);
  const [service4, setService4] = useState(false);
  const [services, setServices] = useState(false);

  const transitionService = () => {
    window.scrollY > 5220 ? setService1(true) : setService1(false);
    window.scrollY > 5520 ? setService2(true) : setService2(false);
    window.scrollY > 5820 ? setService3(true) : setService3(false);
    window.scrollY > 6120 ? setService4(true) : setService4(false);

    window.scrollY > 3820 ? setServices(true) : setServices(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", transitionService);
    // clean-up function
    return () => window.removeEventListener("scroll", transitionService);
  }, []);

  const serviceData = [
    {
      icon: require("../assets/services/tools.png"),
      heading: "Residential Construction",
      text: "Custom Home Building, Renovations and Additions, Multi-Family Housing Development, Residential Remodeling.",
      selector: service1,
    },
    {
      icon: require("../assets/services/engineer.png"),
      heading: "Design-Build Services",
      text: "Comprehensive Design and Construction Services, Streamlined Project Management, Cost-Effective Solutions, Single Point of Contact.",
      selector: service2,
    },
    {
      icon: require("../assets/services/excavator.png"),
      heading: "Land Development",
      text: "Site Planning and Development, Subdivision Design and Layout Infrastructure and Utilities Installation, Land Use Planning and Zoning Compliance.",
      selector: service3,
    },
    {
      icon: require("../assets/services/tripod.png"),
      heading: "Commercial Construction",
      text: "Office Buildings, Retail Spaces, Hospitality and Entertainment Venues, Industrial Facilities.",
      selector: service4,
    },
  ];

  return (
    <section id="services" className="services-bg bg-fixed">
      <div id="services" className="bg-[#f6f6f6] bg-opacity-[0.97] py-12">
        <div className="text-black mx-auto text-center mb-10 | md:mb-16">
          <p className="font-semibold text-pryColor text-xl | md:text-3xl">Our Services</p>

          <div className="flex w-[50%] max-w-3xl mx-auto my-4">
            <hr className="border-none h-[1px] w-full bg-[#cfcfcf]" />
            <hr className="border-none h-[1px] w-[200px] mx-auto m-auto bg-pryColor" />
            <hr className="border-none h-[1px] w-full bg-[#cfcfcf]" />
          </div>

          <p className="w-[80%] mx-auto text-center mb-8 font-medium text-lg | md:text-xl">
            We Bring Our Clients' Dream Homes To Life
          </p>
        </div>

        <div
          className={`flex flex-wrap justify-center gap-4 max-w-[100%] mx-auto | xl:max-w-[1400px] | transition-all duration-1000 ${
            services == true
              ? "md:opacity-100 md:translate-y-0"
              : "opacity-100 translate-y-0 | md:opacity-0 md:translate-y-8"
          }`}
        >
          {serviceData.map(({ i, icon, heading, text, selector }) => (
            <ServicesCard key={i} icon={icon} heading={heading} text={text} selector={selector} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Service;

export const ServicesCard = ({ icon, heading, text, selector }) => {
  return (
    <div
      className={`service__card  w-[90%] h-[320px]  border-white border-[1px] transition duration-1000 | md:hover:border-[#c1892c] | md:max-w-[40%] xl:max-w-[520px] 2xl:max-w-[320px] 2xl:h-[350px] ${
        selector == true
          ? "opacity-100 translate-y-0"
          : "opacity-0 translate-y-8 | md:opacity-100 md:translate-y-0"
      }`}
    >
      <div className="bg-white px-4 text-[#191919] text-center w-full mx-auto flex flex-col items-center justify-start h-full pt-12">
        <div className="mb-6">
          <img className="w-[60px]" src={icon} alt={heading} />
          <hr className="-mt-12 -ml-6 w-20 h-20 rounded-full  bg-[#a27322] bg-opacity-10" />
        </div>

        <p className="font-semibold text-xl mb-4">{heading}</p>

        <p className="text-sm font-medium max-w-sm">{text}</p>
      </div>
    </div>
  );
};
