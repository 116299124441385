import Testimonials from "../ui/Testimonials";
import Portfolio from "../ui/Portfolio";
import About from "../ui/About";
import Services from "../ui/Services";
import Hero from "../ui/Hero";
import Contact, { WhatsApp } from "../ui/Contact";
import Schedule from "../ui/Schedule";

const MainPage = ({ schedule, setSchedule }) => {
  return (
    <main className=" w-full ">
      <Hero />

      <About />

      <Services />

      <Portfolio />

      <Testimonials />

      {schedule == true && <Schedule schedule={schedule} setSchedule={setSchedule} />}
      <Contact />

      <WhatsApp />
    </main>
  );
};

export default MainPage;
