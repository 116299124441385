import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./app.css";
import Footer from "./ui/Footer";

import Header from "./ui/Header";
import MainPage from "./pages/MainPage";
import About from "./pages/About";

import { Toaster } from "react-hot-toast";
import { useState } from "react";

function App() {
  const [schedule, setSchedule] = useState(false);

  return (
    <Router>
      <Toaster />
      <Header schedule={schedule} setSchedule={setSchedule} />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <MainPage schedule={schedule} setSchedule={setSchedule} />
            </>
          }
        />
        <Route path="/about" element={<About />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
