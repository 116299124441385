import React, { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import { Autoplay, EffectCreative, Navigation, Pagination } from "swiper/modules";

import { FbIcon, InstaIcon, LinkedInIcon } from "./Header";

const Portfolio = () => {
  const [projectIndex, setProjectIndex] = useState("all");

  return (
    <section id="portfolio" className="sections-bg bg-fixed w-full py-12">
      <div className="text-[#08221F] mx-auto text-center mb-14">
        <p className="font-semibold text-pryColor text-xl | md:text-3xl">Our Projects</p>

        <div className="flex w-[50%] max-w-3xl mx-auto my-4">
          <hr className="border-none h-[1px] w-full bg-[#cfcfcf]" />
          <hr className="border-none h-[1px] w-[200px] mx-auto m-auto bg-pryColor" />
          <hr className="border-none h-[1px] w-full bg-[#cfcfcf]" />
        </div>

        <p className="w-[80%] mx-auto text-center mb-8 font-semibold text-xl | md:text-xl">
          Explore Some Of Our Exquisite Realty Projects
        </p>
      </div>

      <ul className="flex gap-x-4 mb-10 ml-[5%] | sm:ml-[20%] xl:ml-[25%]">
        {["all", "residential", "commercial", "land"].map((item, i) => (
          <li
            key={i}
            onClick={() => setProjectIndex(item)}
            className={`cursor-pointer capitalize font-semibold transition-all duration-300 ${
              item == `${projectIndex}`
                ? " border-[#a27322] text-[#a27322] border-b-2"
                : "text-black"
            }`}
          >
            {item}
          </li>
        ))}
      </ul>

      <div className="flex flex-wrap justify-center gap-8 max-w-[1320px] mx-auto">
        {portfolioData.map(
          ({ i, imgUrl, title, location, category, fbLink, instaLink, inLink }) => (
            <PortfolioCard
              key={i}
              thumbnail={imgUrl}
              projectTitle={title}
              location={location}
              category={category}
              fbLink={fbLink}
              instaLink={instaLink}
              inLink={inLink}
              projectIndex={projectIndex}
            />
          )
        )}
      </div>
    </section>
  );
};

export default Portfolio;

export const PortfolioCard = ({
  thumbnail,
  projectTitle,
  location,
  category,
  fbLink,
  instaLink,
  inLink,
  projectIndex,
}) => {
  return (
    <div
      className={`project__car w-[90%] cursor-pointer rounded-md | sm:max-w-sm md:max-w-lg lg:max-w-sm xl:max-w-md 
      ${category == `${projectIndex}` || projectIndex == "all" ? "block" : "hidden"}

      `}
    >
      {
        <Swiper
          modules={[Autoplay, Pagination, Navigation, EffectCreative]}
          grabCursor={true}
          // effect={"creative"}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: ["-20%", 0, -400],
              opacity: 0,
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          loop={true}
          autoplay={{ delay: 0, pauseOnMouseEnter: true }}
          speed={3000}
          slidesPerView={1}
          centeredSlides={true}
          spaceBetween={0}
          style={{
            "--swiper-theme-color": "#cfcfcf",
            "--swiper-pagination-bullet-inactive-color": "#cfcfcf",
            "--swiper-pagination-bullet-inactive-opacity": "0.7",
          }}
          className="h4"
        >
          <div>
            {thumbnail.map(({ i, img }) => (
              <SwiperSlide>
                <img
                  key={i}
                  className="about__image mb- h-80 w-[100%] object-cover | md:h-[20rem]"
                  src={img}
                  alt={img}
                />
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      }
      <div className="mt-6 mb-3">
        <p className="text-[1.1rem] font-semibold truncate">{projectTitle}</p>
        <p className="mb-3">{location}</p>
        {/* <div className="flex ">
          <a
            href={fbLink}
            className="flex items-center cursor-pointer bg-[#08221F] bg-opacity-100 scale-75 rounded-lg px-2 py-2 font-semibold shadow-sm hover:-translate-y-1 transition duration-500"
          >
            <FbIcon fill={"#fff"} />
          </a>
          <a
            href={instaLink}
            className="flex items-center cursor-pointer bg-[#08221F] bg-opacity-100 scale-75 px-2 py-2 rounded-lg shadow-sm hover:-translate-y-1 transition duration-500"
          >
            <InstaIcon fill={"#fff"} />
          </a>
          <a
            href={inLink}
            className="flex items-center cursor-pointer bg-[#08221F] bg-opacity-100 scale-75 px-2 py-2 rounded-lg shadow-sm hover:-translate-y-1 transition duration-500"
          >
            <LinkedInIcon fill={"#fff"} />
          </a>
        </div> */}
      </div>
    </div>
  );
};

export const portfolioData = [
  {
    imgUrl: [
      { img: require("../assets/projects/residential/duplex21.jpg") },
      { img: require("../assets/projects/residential/duplex22.jpg") },
      { img: require("../assets/projects/residential/duplex23.jpg") },
      { img: require("../assets/projects/residential/duplex24.jpg") },
      { img: require("../assets/projects/residential/duplex25.jpg") },
      { img: require("../assets/projects/residential/duplex26.jpg") },
      { img: require("../assets/projects/residential/duplex27.jpg") },
    ],
    title: "6 Bedroom Detached Duplex + BQ",
    location: "Emene, Enugu State, Nigeria",
    category: "residential",
    instaLink: "xyz",
    fbLink: "xyz",
    inLink: "xyz",
  },
  {
    imgUrl: [
      { img: require("../assets/projects/commercial/shop1.jpg") },
      { img: require("../assets/projects/commercial/shop2.jpg") },
      { img: require("../assets/projects/commercial/shop3.jpg") },
      { img: require("../assets/projects/commercial/shop4.jpg") },
    ],
    title: "Shopping mall",
    location: "Uyo, Akwa Ibom State, Nigeria",
    category: "commercial",
    instaLink: "xyz",
    fbLink: "xyz",
    inLink: "xyz",
  },
  {
    imgUrl: [
      { img: require("../assets/projects/residential/duplex11.jpg") },
      { img: require("../assets/projects/residential/duplex12.jpg") },
      { img: require("../assets/projects/residential/duplex13.jpg") },
      { img: require("../assets/projects/residential/duplex14.jpg") },
    ],
    title: "4 Bedroom Full Detach Duplex + 1 Room BQ",
    location: "Lugbe, Abuja, Nigeria",
    category: "residential",
    instaLink: "xyz",
    fbLink: "xyz",
    inLink: "xyz",
  },
  {
    imgUrl: [
      { img: require("../assets/projects/land/land1.webp") },
      { img: require("../assets/projects/land/land2.webp") },
      { img: require("../assets/projects/land/land3.webp") },
      { img: require("../assets/projects/land/land4.webp") },
    ],
    title: "400 X 800 Land",
    location: "Obinze, Imo State, Nigeria",
    category: "land",
    instaLink: "xyz",
    fbLink: "xyz",
    inLink: "xyz",
  },
];
