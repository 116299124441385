import { useEffect, useState } from "react";
import aboutUsImg from "../assets/about/about_us.png";

import visionUsImg from "../assets/about/vision.png";
import missionImg from "../assets/about/mission.png";
import objectivesImg from "../assets/about/objectives.png";
import ceoImg from "../assets/about/ceoImg.jpg";
import Hero from "../ui/Hero";

const About = () => {
  const [about, setAbout] = useState(false);
  const [vision, setVision] = useState(false);
  const [mission, setMission] = useState(false);
  const [objectives, setObjectives] = useState(false);
  const [ourTeam, setOurTeam] = useState(false);

  const [moreAbout, setMoreAbout] = useState(false);
  const [moreVision, setMoreVision] = useState(false);
  const [moreMission, setMoreMission] = useState(false);

  const transitionBar = () => {
    window.scrollY > 220 ? setAbout(true) : setAbout(false);
    window.scrollY > 850 ? setVision(true) : setVision(false);
    window.scrollY > 1520 ? setMission(true) : setMission(false);
    window.scrollY > 2020 ? setObjectives(true) : setObjectives(false);
    window.scrollY > 2520 ? setOurTeam(true) : setOurTeam(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", transitionBar);
    // clean-up function
    return () => window.removeEventListener("scroll", transitionBar);
  }, []);

  return (
    <section id="about" className="bg-[#a27322] bg-opacity-5">
      <Hero />

      <div className="bg-white">
        <div
          className={`bg-[#a27322] bg-opacity-5 transition-all duration-1000 delay-100 ease-in-out | md:delay-0 ${
            about == true
              ? "opacity-100 translate-y-0"
              : "opacity-0 translate-y-8 | d:opacity-100 d:translate-y-0"
          }`}
        >
          <div className="px-[5%] mx-auto flex flex-col items-center justify-center gap-12 pt-32 pb-16 | md:pt-32 md:gap-12 md:flex-row-reverse md:items-start | 2xl:px-[12.5%]">
            <div className="md:w-1/2">
              <div className="flex items-center mb-6">
                <hr className="h-[2px] w-4 mr-1 border-none bg-pryColor" />
                <p className="font-semibold text-pryColor">About us</p>
              </div>

              <p className="text-3xl mb-6 mx-auto font-medium">
                We are a big player in the realty construction industry
              </p>

              <div className="relative space-y-5">
                <p className={`space-y-5`}>
                  <span className={`block`}>
                    Chekwas Realty Construction Company specializes in a wide range of services.
                    Including residential, commercial, and industrial construction projects.
                  </span>

                  <span className={`block`}>
                    With a strong focus on quality, innovation, and customer satisfaction, we have
                    established a solid reputation for delivering exceptional results.
                  </span>
                </p>
                <p className={`space-y-5 ${moreAbout !== true && "line-clamp-3"}`}>
                  <span className={`block`}>
                    We have been in business for over a decade now and are based in Enugu state,
                    Nigeria. Our team of experienced professionals specializes in all aspects of
                    construction, from design and planning to execution and project management.
                  </span>

                  <span className={`block`}>
                    We are committed to delivering projects on time, within budget, and to the
                    highest quality standards. We also prioritize customer satisfaction and the
                    maintenance of healthy, thriving professional relationships.
                  </span>
                </p>

                {moreAbout !== true && <div className="about-topShadow-1" />}
              </div>

              <button
                onClick={() => setMoreAbout(!moreAbout)}
                className={`flex items-center bg-black text-[#f7f7f7] text-sm font-medium mt-6 px-3 py-3`}
              >
                {moreAbout == true ? "Show less" : "Read more"}
                <svg
                  className={`ml-1 h-[8px] transition duration-500 ${
                    moreAbout == true ? "rotate-180" : "rotate-0"
                  }`}
                  viewBox="0 0 30 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27 3L15 15L3 3"
                    stroke="#fff"
                    stroke-width="3"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>

            <img
              className="about__image w-full h-[220px] object-cover | md:w-1/2 md:h-[520px] md:mr-[5%]"
              src={aboutUsImg}
              alt="picture of our project"
            />
          </div>
        </div>

        <div
          className={`transition-all duration-1000 ease-in-out delay-1000 | md:delay-0 ${
            vision == true ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8 "
          }`}
        >
          <div className="px-[5%] mx-auto flex flex-col items-center justify-center gap-12 py-16 | md:gap-12 md:flex-row md:items-start | 2xl:px-[12.5%]">
            <div className="md:w-1/2">
              <div className="flex items-center mb-6">
                <hr className="h-[2px] w-4 mr-1 border-none bg-pryColor" />
                <p className="font-semibold text-pryColor">Our Vision</p>
              </div>
              <p className="text-3xl font-medium">
                We aim to build a legacy of excellence by exceeding standards in craftsmanship and
                professionalism
              </p>
              <div>
                <div className="relative">
                  <p className={`font- text- my-6 mb-3 ${moreVision == false && "line-clamp-6"}`}>
                    We envision a future where Chekwas Realty Construction Company is synonymous
                    with reliability, efficiency, and innovation in the construction industry.
                  </p>

                  <p className={`font- text- my-6 mb-3 ${moreVision == false && "line-clamp-3"}`}>
                    By embracing cutting-edge technologies, fostering a culture of continuous
                    improvement, and prioritizing sustainability in all our projects, we aim to set
                    new benchmarks for excellence and make a positive impact on the communities we
                    serve.
                  </p>
                  {moreVision == false && <div className="about-topShadow" />}
                </div>

                <button
                  onClick={() => setMoreVision(!moreVision)}
                  className={`flex items-center bg-black text-[#f7f7f7] text-sm font-medium mt-6 px-3 py-3`}
                >
                  {moreVision == true ? "Show less" : "Read more"}
                  <svg
                    className={`ml-1 h-[8px] transition duration-500 ${
                      moreVision == true ? "rotate-180" : "rotate-0"
                    }`}
                    viewBox="0 0 30 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M27 3L15 15L3 3"
                      stroke="#fff"
                      stroke-width="3"
                      stroke-linecap="square"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <img
              className="about__image w-full h-[320px] object-cover | md:w-1/2 md:h-[520px] md:ml-[5%]"
              src={missionImg}
              alt="Picture about our company"
            />
          </div>
        </div>

        <div
          className={`bg-[#a27322] bg-opacity-5 transition-all duration-1000 ease-in-out delay-1000 | md:delay-0 ${
            mission == true ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
          }`}
        >
          <div className="px-[5%] mx-auto flex flex-col items-center justify-center gap-12 py-16 | md:gap-12 md:flex-row-reverse md:items-start | 2xl:px-[12.5%]">
            <div className="md:w-1/2">
              <div className="flex items-center mb-4">
                <hr className="h-[2px] w-4 mr-1 border-none bg-pryColor" />
                <p className="font-semibold text-pryColor">Our Mission</p>
              </div>
              <p className="text-3xl font-medium">
                Our mission is to deliver high-quality construction services with integrity,
                professionalism, and excellence
              </p>
              <div>
                <div className="relative">
                  <p className={`font- my-6 mb-3 ${moreMission == false && "line-clamp-6"}`}>
                    We strive to exceed our clients' expectations by providing innovative solutions,
                    superior craftsmanship, and timely project delivery. Our goal is to build
                    long-lasting relationships based on trust, reliability, and exceptional
                    performance. We are dedicated to creating sustainable and safe environments
                    while upholding the highest standards of ethics and accountability in all our
                    endeavors.
                  </p>

                  {moreMission == false && <div className="about-topShadow-1" />}
                </div>

                <button
                  onClick={() => setMoreMission(!moreMission)}
                  className={`flex items-center bg-black text-[#f7f7f7] text-sm font-medium mt-6 px-3 py-3`}
                >
                  {moreMission == true ? "Show less" : "Read more"}
                  <svg
                    className={`ml-1 h-[8px] transition duration-500 ${
                      moreMission == true ? "rotate-180" : "rotate-0"
                    }`}
                    viewBox="0 0 30 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M27 3L15 15L3 3"
                      stroke="#fff"
                      stroke-width="3"
                      stroke-linecap="square"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <img
              className="about__image w-[100%] h-[420px] object-cover | md:w-1/2 md:h-[520px] md:mr-[5%]"
              src={visionUsImg}
              alt="Picture about our company"
            />
          </div>
        </div>

        <div
          className={`transition-all duration-1000 ease-in-out delay-1000 | md:delay-0 ${
            objectives == true ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
          }`}
        >
          <div className="px-[5%] mx-auto flex flex-col items-center justify-center gap-12 py-16 | md:gap-12 md:flex-row md:items-center | 2xl:px-[12.5%]">
            <div className="md:w-1/2">
              <div className="flex items-center mb-4">
                <hr className="h-[2px] w-4 mr-1 border-none bg-pryColor" />
                <p className="font-semibold text-pryColor">Our Objectives</p>
              </div>
              <p className="text-3xl font-medium">
                At Chekwas Realty Construction Company we prioritize:
              </p>
              <div className="mt-5">
                <ul className="objective__list pl-4">
                  <li>Customer Satisfaction</li>
                  <li>Improve operational efficiency</li>
                  <li>Ensure safety and compliance</li>
                  <li>Enhance sustainability</li>
                  <li>Enhance technology integration</li>
                </ul>
              </div>
            </div>

            <img
              className="about__image hidden w-[100%] h-[320px] object-cover | md:flex md:w-1/2 md:h-[520px] md:ml-[5%]"
              src={objectivesImg}
              alt="Picture about our company"
            />
          </div>
        </div>
      </div>

      <div
        className={`transition-all duration-1000 ease-in-out delay-1000 | md:delay-0 ${
          ourTeam == true ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
        }`}
      >
        <div className={`px-[5%] mx-auto py-12 | 2xl:px-[12.5%] md:flex md:items-start`}>
          <p className="mb-8 text-[#a27322] text-xl font-bold | md:hidden">
            Meet Our Management Team
          </p>
          <img
            className="w-full max-w-xl h-full object-top object-cover mb-6 | md:w-1/2 md:mr-[5%]"
            src={ceoImg}
            alt="our CEO"
          />

          <div className="flex flex-col | md:flex-col-reverse">
            <div>
              <p className="font-bold text-xl mb-">Engr. Chukwujekwu Onwuchekwa</p>
              <p className="mb-4">Title: CEO</p>
            </div>
            <div>
              <p className="mb-8 text-[#a27322] text-4xl font-bold hidden | md:block">
                Meet Our Management Team
              </p>
              <p className="md:mb-6">
                Explore with us, a journey through stunning architecture, where every building tells
                a story of creativity, eco-friendliness, and enduring beauty, enchanting both hearts
                and minds, transforming into living masterpieces. Let us bring your dream of owning
                real estate to life.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
