import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import { EffectCreative, Navigation, Pagination } from "swiper/modules";

const Testimonials = () => {
  return (
    <section id="testimonials" className="bg-[#F3F6EF]] pt-[40px] pb-[20px] lg:pb-[40px]">
      <div className="text-[#08221F] mx-auto text-center mb-8">
        <p className="font-semibold text-pryColor text-2xl | md:text-3xl">Testimonials</p>

        <div className="flex w-[50%] max-w-3xl mx-auto my-6">
          <hr className="border-none h-[1px] w-full bg-[#cfcfcf]" />
          <hr className="border-none h-[1px] w-[200px] mx-auto m-auto bg-pryColor" />
          <hr className="border-none h-[1px] w-full bg-[#cfcfcf]" />
        </div>

        <p className="text-[#08221F] w-[90%] mx-auto text-center mb-8 font-semibold text-xl | md:text-3xl">
          Hear What Our Clients Have To Say About Us
        </p>
      </div>

      <div className="p-3 mx-auto | md:max-w-[620px]">
        <Swiper
          modules={[Pagination, Navigation, EffectCreative]}
          grabCursor={true}
          // effect={"creative"}
          creativeEffect={{
            prev: {
              shadow: false,
              translate: ["-100", 0, -400],
              opacity: 0,
            },
            next: {
              translate: [20, 0, 20],
              opacity: 0,
            },
          }}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          navigation={{
            enabled: false,
          }}
          loop={true}
          slidesPerView={1}
          centeredSlides={true}
          spaceBetween={14}
          style={{
            "--swiper-theme-color": "#08221F",
            "--swiper-pagination-bullet-inactive-color": "#08221F",
            "--swiper-pagination-bullet-inactive-opacity": "0.7",
            "--swiper-navigation-top-offset": "98.6%",
            "--swiper-navigation-sides-offset": "25%",
            "--swiper-navigation-size": "30px",
            "--swiper-navigation-bg": "#08221F",
          }}
          breakpoints={{
            // when window width is >= 640px
            768: {
              navigation: {
                enabled: true,
              },
            },
          }}
        >
          <div>
            {testimonial.map(({ img, title, name, designation, jobDesc }) => (
              <SwiperSlide className="mb-12 lg:mb-16">
                <TestimonialCard
                  img={img}
                  title={title}
                  name={name}
                  designation={designation}
                  jobDesc={jobDesc}
                />
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </div>
    </section>
  );
};

export default Testimonials;

export const TestimonialCard = ({ img, title, name, designation, job, jobDesc }) => {
  return (
    <div className="h-[380px] mt-[10px] mb-[10px] bg-white bg-[#9f7638 flex flex-col items-center justify-center px-4 text-center">
      <p className="font-medium text-lg italic py-6 lg:text-lg">
        <svg
          width="28"
          height="28"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.9999 12C12.2652 12 12.5195 11.8946 12.707 11.7071C12.8946 11.5196 12.9999 11.2652 12.9999 11V8.558C12.9999 8.29278 12.8946 8.03843 12.707 7.85089C12.5195 7.66336 12.2652 7.558 11.9999 7.558H10.6119C10.6119 7.20667 10.6326 6.85533 10.6739 6.504C10.7359 6.132 10.8393 5.80133 10.9839 5.512C11.1286 5.22267 11.3149 4.995 11.5429 4.829C11.7696 4.643 12.0589 4.55 12.4109 4.55V3C11.8323 3 11.3256 3.124 10.8909 3.372C10.4593 3.61687 10.0884 3.95595 9.80594 4.364C9.52155 4.81315 9.31215 5.30559 9.18594 5.822C9.05832 6.39174 8.99592 6.97415 8.99994 7.558V11C8.99994 11.2652 9.1053 11.5196 9.29283 11.7071C9.48037 11.8946 9.73472 12 9.99994 12H11.9999ZM5.99994 12C6.26515 12 6.51951 11.8946 6.70704 11.7071C6.89458 11.5196 6.99994 11.2652 6.99994 11V8.558C6.99994 8.29278 6.89458 8.03843 6.70704 7.85089C6.51951 7.66336 6.26515 7.558 5.99994 7.558H4.61194C4.61194 7.20667 4.6326 6.85533 4.67394 6.504C4.7366 6.132 4.83994 5.80133 4.98394 5.512C5.12861 5.22267 5.31494 4.995 5.54294 4.829C5.76961 4.643 6.05894 4.55 6.41094 4.55V3C5.83227 3 5.3256 3.124 4.89094 3.372C4.45928 3.61687 4.08841 3.95595 3.80594 4.364C3.52155 4.81315 3.31215 5.30559 3.18594 5.822C3.05832 6.39174 2.99592 6.97415 2.99994 7.558V11C2.99994 11.2652 3.10529 11.5196 3.29283 11.7071C3.48037 11.8946 3.73472 12 3.99994 12H5.99994Z"
            fill="#08221F"
          />
        </svg>
        {jobDesc}
      </p>

      <img className="h-20 w-20 rounded-full border-4 border-[#57401e mb-3" src={img} alt={name} />
      <div className="text-center">
        <p className="font-semibold text-lg -mb-0.5">{name}</p>
        <p className="text-xs italic">{designation}</p>
      </div>
    </div>
  );
};

export const testimonial = [
  {
    img: require("../assets/testimonial-img/Pexels Photo by Steffen Coonan.png"),
    name: "Okoroafor Nweze",
    designation: "Udi, Enugu State, Nigeria",
    jobDesc:
      "I highly recommend Chekwas Construction to anyone in search of their dream home. From start to finish, they exceeded my expectations, delivering personalized service and guidance every step of the way.",
  },
  {
    img: require("../assets/testimonial-img/unsplash_2keCPb73aQY.png"),
    name: "Katie Woods",
    designation: "Miami, Florida USA",
    jobDesc:
      "Working with Chekwas Construction was an absolute pleasure! Their attention to detail and commitment to quality craftsmanship truly set them apart. We couldn't be happier with our new home.",
  },
  {
    img: require("../assets/testimonial-img/unsplash_fFqqVSi96z0.png"),
    name: "Enobong Joseph",
    designation: "Uyo, Akwa Ibom State, Nigeria",
    jobDesc:
      "From start to finish, Chekwas Construction exceeded our expectations. Their team was professional, responsive, and dedicated to making our dream home a reality.",
  },
  {
    img: require("../assets/testimonial-img/unsplash_g39p1kDjvSY.png"),
    name: "Akudike Nwanchor",
    designation: "Owerri, Imo State, Nigeria",
    jobDesc:
      "We highly recommend them to anyone looking for top-notch construction services. We couldn't be more thrilled with our new building.",
  },
  {
    img: require("../assets/testimonial-img/unsplash_mR1CIDduGLc.png"),
    name: "Dike Onyekachi",
    designation: "Asaba, Delta State",
    jobDesc:
      "Choosing Chekwas Construction for our hospital building was the best decision we ever made. Not only did they deliver on time and within budget, but their expertise in the market helped us secure a great return on our investment.",
  },
  {
    img: require("../assets/testimonial-img/unsplash_ylyn5r4vxcA.png"),
    name: "Andreas Gonzalez",
    designation: "Neuquen, Argentina",
    jobDesc:
      "At first we were nervous about the process, but Chekwas Construction made it seamless. They guided us through every step, listened to our needs, and delivered a home that exceeded our expectations. We're forever grateful.",
  },
  {
    img: require("../assets/testimonial-img/1714498475678.png"),
    name: "Nze Nwadilibe",
    designation: "Abakaliki, Ebonyi",
    jobDesc:
      "We've worked with Chekwas Construction on multiple projects, and each time they have delivered exceptional results.",
  },
  {
    img: require("../assets/testimonial-img/1714498475769.png"),
    name: "Emeka Chinonso",
    designation: "New-Owerri, Imo State, Nigeria",
    jobDesc:
      "Their professionalism, integrity, and attention to detail are unmatched. We wouldn't trust anyone else with our real estate needs.",
  },
];
