import React, { useEffect, useState } from "react";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import toast from "react-hot-toast";
import emailjs from "@emailjs/browser";

import contactImg from "../assets/contact/contactUs_img.svg";
import { CallIcon, EmailIcon, FbIcon, InstaIcon, LinkedInIcon, TiktokIcon } from "./Header";

const Contact = () => {
  const [senderName, setSenderName] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [senderPhone, setSenderPhone] = useState("");
  const [senderMessage, setSenderMessage] = useState("");

  const sendEmail = async (e) => {
    e.preventDefault();
    toast.loading("Sending...");

    // Your EmailJS services ID, template ID, and Public key
    const serviceId = process.env.REACT_APP_contact_serviceId;
    const templateId = process.env.REACT_APP_contact_templateId;
    const publicKey = process.env.REACT_APP_contact_publicKey;

    // Create a new object that contains dynamic template params
    const templateParams = {
      from_name: senderName,
      from_email: "info@chekwasconstruction.com",
      to_name: "Chekwas Construction Company Ltd",
      phone: senderPhone,
      message: senderMessage,
      reply_to: senderEmail,
    };

    // Send the email using EmailJS
    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        toast.dismiss();
        toast.success("Email sent successfully!");
        setSenderName("");
        setSenderEmail("");
        setSenderPhone("");
        setSenderMessage("");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <section className="sections-b bg-fixed">
      <section
        id="contact"
        className="bg-[#a27322] bg-opacity-5 backdrop-blur-[2px] text-black relative flex flex-col pt-[40px] pb-[20px] w-full"
      >
        <div className="text-[#08221F] mx-auto text-center mb-8">
          <p className="font-semibold text-pryColor text-xl | md:text-3xl">Contact us</p>

          <div className="flex w-[50%] max-w-3xl mx-auto my-6">
            <hr className="border-none h-[1px] w-full bg-[#cfcfcf]" />
            <hr className="border-none h-[1px] w-[200px] mx-auto m-auto bg-pryColor" />
            <hr className="border-none h-[1px] w-full bg-[#cfcfcf]" />
          </div>

          <p className="w-[90%] mx-auto text-center mb-12 font-semibold text-xl | md:w-full md:text-3xl">
            Looking for Your Dream Home? Let's Talk!
          </p>
        </div>

        <div className="text-black flex flex-row-reverse items-start w-full max-w-6xl mx-auto">
          <form className="max-w-[480px] mx-auto | md:w-1/2" onSubmit={(e) => sendEmail(e)}>
            <label className="block mb-1 text-sm">Your name</label>
            <input
              className="bg-transparent rounded-sm outline-none border-[0.8px] border-black p-[5px] mb-[24px] w-full h-[48px] text-sm"
              value={senderName}
              onChange={(e) => setSenderName(e.target.value)}
              placeholder="Full name"
              type="text"
              required
            />

            <label className="block mb-1 text-sm">Email address</label>
            <input
              className="bg-transparent rounded-sm outline-none border-[0.8px] border-black p-[5px] mb-[24px] w-full h-[48px] text-sm"
              value={senderEmail}
              onChange={(e) => setSenderEmail(e.target.value)}
              type="email"
              placeholder="Valid email"
              required
            />

            <label className="block mb-1 text-sm">Phone number</label>
            <input
              className="bg-transparent rounded-sm outline-none border-[0.8px] border-black p-[5px] mb-[24px] w-full h-[48px] text-sm"
              value={senderPhone}
              onChange={(e) => setSenderPhone(e.target.value)}
              type="tel"
              placeholder="Valid phone number"
              required
            />

            <label className="block mb-1 text-sm">Your message</label>
            <textarea
              className="bg-transparent rounded-sm outline-none border-[0.8px] border-black p-[5px] mb-[0px] w-full h-[122px] text-sm"
              value={senderMessage}
              onChange={(e) => setSenderMessage(e.target.value)}
              required
              rows={6}
            />

            <div className="flex items-center mt-3">
              <input
                className="w-full md:w-min px-24 bg-pryColor rounded-sm h-[52px] text-white font-medium shadow-[#4a4a4a27] shadow  transition duration-300 hover:bg-[#7d5d2c] cursor-pointer border-none"
                type="submit"
                value="Send"
              />

              <div className="hidden ml-10 | md:block">
                <Socials show={"flex"} />
              </div>
            </div>

            <div className="mx-auto w-[98%] my-10 flex flex-col">
              <div>
                <span className="flex items-center text-sm mb-2">
                  <CallIcon fill={"#08221F"} />
                  <p className="ml-2">+234 705 9130 745</p>
                </span>
                <span className="flex items-center text-sm mb-2">
                  <EmailIcon fill={"#08221F"} />
                  <p className="ml-2">info@chekwasconstruction.com</p>
                </span>
                <span className="flex text-sm">
                  <svg
                    width="18"
                    height="21"
                    viewBox="0 0 18 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.25 9C4.25 7.74022 4.75045 6.53204 5.64124 5.64124C6.53204 4.75045 7.74022 4.25 9 4.25C10.2598 4.25 11.468 4.75045 12.3588 5.64124C13.2496 6.53204 13.75 7.74022 13.75 9C13.75 10.2598 13.2496 11.468 12.3588 12.3588C11.468 13.2496 10.2598 13.75 9 13.75C7.74022 13.75 6.53204 13.2496 5.64124 12.3588C4.75045 11.468 4.25 10.2598 4.25 9ZM9 5.75C8.13805 5.75 7.3114 6.09241 6.7019 6.7019C6.09241 7.3114 5.75 8.13805 5.75 9C5.75 9.86195 6.09241 10.6886 6.7019 11.2981C7.3114 11.9076 8.13805 12.25 9 12.25C9.86195 12.25 10.6886 11.9076 11.2981 11.2981C11.9076 10.6886 12.25 9.86195 12.25 9C12.25 8.13805 11.9076 7.3114 11.2981 6.7019C10.6886 6.09241 9.86195 5.75 9 5.75Z"
                      fill="#08221F"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.52401 7.857C0.694937 5.7838 1.63924 3.85069 3.16942 2.44147C4.69961 1.03226 6.70378 0.249994 8.78401 0.25H9.21601C11.2962 0.249994 13.3004 1.03226 14.8306 2.44147C16.3608 3.85069 17.3051 5.7838 17.476 7.857C17.6662 10.1663 16.9528 12.4593 15.486 14.253L10.693 20.114C10.4879 20.3648 10.2296 20.567 9.9368 20.7057C9.64401 20.8445 9.32403 20.9165 9.00001 20.9165C8.67599 20.9165 8.35601 20.8445 8.06322 20.7057C7.77042 20.567 7.51213 20.3648 7.30701 20.114L2.51401 14.253C1.04713 12.4593 0.333696 10.1663 0.52401 7.857ZM8.78401 1.75C7.08049 1.75026 5.43935 2.39103 4.18633 3.54512C2.93332 4.69921 2.16005 6.28225 2.02001 7.98C1.86161 9.90186 2.4553 11.8102 3.67601 13.303L8.46901 19.165C8.53344 19.2439 8.6146 19.3074 8.70661 19.351C8.79862 19.3947 8.89918 19.4173 9.00101 19.4173C9.10284 19.4173 9.2034 19.3947 9.29541 19.351C9.38742 19.3074 9.46858 19.2439 9.53301 19.165L14.326 13.303C15.546 11.8099 16.139 9.90159 15.98 7.98C15.84 6.28208 15.0665 4.6989 13.8133 3.54478C12.5601 2.39066 10.9187 1.75001 9.21501 1.75H8.78401Z"
                      fill="#08221F"
                    />
                  </svg>
                  <p className="ml-2">JC Jerry Plaza, Nkpokiti Esut Road, Enugu State.</p>
                </span>
              </div>
            </div>
          </form>

          <img
            className="hidden h-[620px] w-1/2 object-cover about__image | md:mr-[5%] md:block"
            src={contactImg}
            alt="let's talk"
          />
        </div>

        <hr className="mb-6 h-[0.5px] max-w-[720px] mx-auto w-full border-none bg-[#ababab] | md:hidden" />

        <Socials show={"flex"} hide={"hidden"} />
      </section>
    </section>
  );
};

export default Contact;

export const Socials = ({ hide, show }) => {
  return (
    <div className={`${show} justify-center space-x-4 mx-auto | md:${hide}`}>
      <a href="/">
        <InstaIcon fill={"#08221F"} />
      </a>
      <a href="/">
        <FbIcon fill={"#08221F"} />
      </a>
      <a href="/">
        <LinkedInIcon fill={"#08221F"} />
      </a>
    </div>
  );
};

export const WhatsApp = () => {
  const [goToTop, setGoToTop] = useState(false);
  const [whatsApp, setWhatsApp] = useState(false);
  const [chat, setChat] = useState(true);

  const transitionWhatsApp = () => {
    window.scrollY > 400 ? setWhatsApp(true) : setWhatsApp(false);
    window.scrollY > 800 ? setGoToTop(true) : setGoToTop(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", transitionWhatsApp);
    // clean-up function
    return () => window.removeEventListener("scroll", transitionWhatsApp);
  }, []);

  return (
    <div
      className={`fixed z-50 right-4 bottom-16 
      `}
    >
      <ScrollLink
        className={`cursor-pointer  transition-all duration-1000 ${
          goToTop == true ? "opacity-100 translate-x-0" : "opacity-0 translate-x-8"
        }`}
        to={"header"}
        smooth={true}
        offset={0}
      >
        <svg
          className="absolute -top-12 -right-1 h-12"
          width="48"
          height="48"
          viewBox="0 0 48 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M35.991 14L23 28.516H32.527L32.69 40H39.483L39.456 28.516H48.982L35.991 14Z"
            fill="#3F3F3F"
          />
          <path
            d="M24.143 45.5H29.857M27 45.5V55.5M43.142 55.47V45.47H46.955C47.6154 45.47 48.2487 45.7323 48.7157 46.1993C49.1827 46.6663 49.445 47.2996 49.445 47.96C49.4451 48.2871 49.3808 48.611 49.2557 48.9132C49.1307 49.2154 48.9473 49.49 48.716 49.7213C48.4848 49.9527 48.2103 50.1362 47.9081 50.2614C47.6059 50.3866 47.2821 50.451 46.955 50.451H43.142M35.991 14L23 28.516H32.527L32.69 40H39.483L39.456 28.516H48.982L35.991 14ZM36.33 55.47C35.861 55.4701 35.3966 55.3779 34.9633 55.1984C34.53 55.019 34.1363 54.756 33.8046 54.4244C33.473 54.0927 33.21 53.699 33.0306 53.2657C32.8511 52.8324 32.7589 52.368 32.759 51.899V49.04C32.7589 48.571 32.8511 48.1066 33.0306 47.6733C33.21 47.24 33.473 46.8463 33.8046 46.5146C34.1363 46.183 34.53 45.92 34.9633 45.7406C35.3966 45.5611 35.861 45.4689 36.33 45.469C36.799 45.4689 37.2634 45.5611 37.6967 45.7406C38.13 45.92 38.5237 46.183 38.8554 46.5146C39.187 46.8463 39.45 47.24 39.6294 47.6733C39.8089 48.1066 39.9011 48.571 39.901 49.04V51.9C39.9011 52.369 39.8089 52.8334 39.6294 53.2667C39.45 53.7 39.187 54.0937 38.8554 54.4254C38.5237 54.757 38.13 55.02 37.6967 55.1994C37.2634 55.3789 36.799 55.4701 36.33 55.47Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </ScrollLink>

      <div
        className={`flex flex-col items-end transition-all duration-1000 ${
          whatsApp == true ? "opacity-100 translate-x-0" : "opacity-0 translate-x-8"
        }`}
      >
        <div className={`flex items-center`}>
          {chat == true ? (
            <p className="relative bg-[#f6f6f6] shadow-lg text-[12px] mr-2 p-2 font-medium rounded-lg">
              <span
                onClick={() => setChat(!chat)}
                className="cursor-pointer absolute -top-1 -right-1 font-bold rounded-full bg-[#f5f5f5] shadow-lg text-xs"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.40038 18.3084L5.69238 17.6004L11.2924 12.0004L5.69238 6.40038L6.40038 5.69238L12.0004 11.2924L17.6004 5.69238L18.3084 6.40038L12.7084 12.0004L18.3084 17.6004L17.6004 18.3084L12.0004 12.7084L6.40038 18.3084Z"
                    fill="black"
                  />
                </svg>
              </span>
              Need Help? <span className="font-semibold block">Chat with us</span>
            </p>
          ) : null}
          <a href="https://wa.me/2347059130745">
            <svg
              className="shadow-lg bg-transparent rounded-full"
              width="28"
              height="28"
              viewBox="0 0 256 258"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_163_7)">
                <path
                  d="M5.46262 127.456C5.45662 149.133 11.1206 170.299 21.8906 188.955L4.43262 252.697L69.6646 235.593C87.7069 245.415 107.922 250.562 128.465 250.563H128.519C196.334 250.563 251.537 195.38 251.566 127.553C251.579 94.6861 238.791 63.7801 215.557 40.5281C192.327 17.2781 161.432 4.46715 128.514 4.45215C60.6906 4.45215 5.49162 59.6321 5.46362 127.456"
                  fill="url(#paint0_linear_163_7)"
                />
                <path
                  d="M1.07 127.416C1.063 149.873 6.93 171.796 18.084 191.12L0 257.147L67.571 239.43C86.189 249.581 107.151 254.933 128.481 254.941H128.536C198.784 254.941 255.97 197.773 256 127.518C256.012 93.47 242.764 61.453 218.7 37.368C194.633 13.286 162.633 0.014 128.536 0C58.276 0 1.098 57.16 1.07 127.416ZM41.311 187.792L38.788 183.787C28.182 166.923 22.584 147.435 22.592 127.424C22.614 69.029 70.138 21.52 128.576 21.52C156.876 21.532 183.472 32.564 203.476 52.58C223.479 72.598 234.486 99.208 234.479 127.51C234.453 185.905 186.928 233.42 128.536 233.42H128.494C109.481 233.41 90.834 228.304 74.572 218.655L70.702 216.36L30.604 226.873L41.311 187.792Z"
                  fill="url(#paint1_linear_163_7)"
                />
                <path
                  d="M96.6776 74.1479C94.2916 68.8449 91.7806 68.7379 89.5116 68.6449C87.6536 68.5649 85.5296 68.5709 83.4076 68.5709C81.2836 68.5709 77.8326 69.3699 74.9156 72.5549C71.9956 75.7429 63.7676 83.4469 63.7676 99.1159C63.7676 114.786 75.1806 129.929 76.7716 132.056C78.3646 134.179 98.8046 167.363 131.177 180.129C158.081 190.738 163.556 188.628 169.395 188.096C175.235 187.566 188.239 180.394 190.892 172.957C193.547 165.521 193.547 159.147 192.751 157.815C191.955 156.488 189.831 155.691 186.646 154.099C183.46 152.506 167.802 144.801 164.883 143.738C161.963 142.676 159.84 142.146 157.716 145.335C155.592 148.519 149.493 155.691 147.634 157.815C145.777 159.944 143.918 160.209 140.734 158.616C137.547 157.018 127.29 153.659 115.121 142.81C105.653 134.368 99.2606 123.943 97.4026 120.754C95.5446 117.57 97.2036 115.844 98.8006 114.257C100.232 112.83 101.987 110.538 103.581 108.679C105.169 106.819 105.699 105.492 106.761 103.368C107.824 101.242 107.292 99.3819 106.497 97.7889C105.699 96.1959 99.5096 80.4449 96.6776 74.1479Z"
                  fill="white"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_163_7"
                  x1="12361.1"
                  y1="24829"
                  x2="12361.1"
                  y2="4.45215"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#1FAF38" />
                  <stop offset="1" stop-color="#60D669" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_163_7"
                  x1="12800"
                  y1="25714.7"
                  x2="12800"
                  y2="0"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F9F9F9" />
                  <stop offset="1" stop-color="white" />
                </linearGradient>
                <clipPath id="clip0_163_7">
                  <rect width="256" height="258" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>

        <div className="flex flex-col space-y-3 bg-[#f6f6f6] bg-opacity-0 p-1 rounded-xl">
          <FbIcon fill={"black"} />
          <InstaIcon fill={"black"} />
          <LinkedInIcon fill={"black"} />
          <TiktokIcon />
        </div>
      </div>
    </div>
  );
};
