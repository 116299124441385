import { useEffect, useState } from "react";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import { Autoplay, EffectCreative, Navigation, Pagination } from "swiper/modules";

const Hero = () => {
  const [statusBar, setStatusBar] = useState(false);

  const transitionBar = () => {
    window.scrollY > 0.5 ? setStatusBar(true) : setStatusBar(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", transitionBar);
    // A clean-up function
    return () => window.removeEventListener("scroll", transitionBar);
  }, []);

  return (
    <section className="hero-bg relative text-[#ece9e2] w-full">
      <div>
        <HeroBg />
      </div>

      <div className="absolute top-0 z-10 uppercase bg-black bg-opacity-50 flex flex-col items-center justify-center w-full px-3 pt-24 h-[100svh] | md:h-[100svh] md:pt-52 md:px-[5%] xl:px-[10%]">
        <div className="text-center tracking-wide">
          <h1 className="font-bold flex flex-col justify-center items-center text-4xl w-full max-w-6xl mx-auto | sm:text-5xl">
            <span className="flex justify-center mb-3 items-baseline font-thin text-lg">
              Welcome to
              <hr className="h-[1px] ml-1 w-16 mx-auto border-none bg-[#e3e3e386]" />
            </span>
            Chekwas Realty Construction Company.
          </h1>
          <h2 className="w-[90%] capitalize mx-auto text-lg font-light mt-3">
            Your Favorite Real Estate Developer and Consultant.
          </h2>
          <ScrollLink to={"about"} smooth={true} offset={0}>
            <button
              className={`cta--animation bg-[#705018] uppercase text-[#eeece9] font-medium px-6 py-4 mt-10 mx-auto text-sm shadow-lg hover:bg-[#5c4112] transition duration-500 | d:block md:mt-14 ${
                statusBar !== true
                  ? "statusBar-animation"
                  : "opacity-0 translate-y-8 | md:opacity-100 md:translate-y-0"
              }`}
            >
              Learn more
            </button>
          </ScrollLink>
        </div>

        <div
          className={`absolute z-10 text-[#F3F6EF] rounded-sm -bottom-[50px] left-1/2 -translate-x-1/2 flex flex-row items-center justify-evenly w-[100%] max-w-2xl h-[100px] mx-auto transition duration-1000 | sm:-bottom-12 | md:-bottom-50 ${
            statusBar == true ? "statusBar-animation" : "opacity-0 translate-y-8"
          }`}
        >
          <div className="flex items-center justify-center bg-[#694B17] w-[95%] mx-auto h-full px-4 shadow-lg shadow-[#57575753] | md:w-full">
            <div className="text-center w-full | d:w-[167px] | l:w-[160px]">
              <p className="text-3xl font-medium">10+</p>
              <p className="font-light text-xs mt-1 | sm:text-sm">Years</p>
            </div>

            <hr className="h-[0.3px] w-full border-none bg-[#dbdada86] rotate-90 | md:w-[40%]" />

            <div className="text-center w-full">
              <p className="text-3xl font-medium">50+</p>
              <p className="font-light text-xs mt-1 | sm:text-sm">Projects</p>
            </div>

            <hr className="h-[0.3px] w-full border-none bg-[#dbdada86] rotate-90 | md:w-[40%]" />

            <div className="text-center w-full">
              <p className="text-3xl font-medium">4+</p>
              <p className="font-light text-xs mt-1 | sm:text-sm">Locations</p>
            </div>
          </div>

          <ScrollLink
            className="h-full w-1/3 hidden cursor-pointer text-center text-[#100c04] bg-[#BC8730] items-center justify-center | m:w-[170px] | d:flex | l:w-[220px] | transition-all duration-500 hover:bg-[#7d5a21]"
            to={"portfolio"}
            smooth={true}
            offset={0}
          >
            <p className="text-sm text-center font-bold | md:text-sm md:font-semibold">
              View Properties
            </p>
          </ScrollLink>
        </div>
      </div>
    </section>
  );
};

export default Hero;

const HeroBg = () => {
  const thumbnail = [
    {
      img: require("../assets/hero/hero1.png"),
    },
    {
      img: require("../assets/hero/hero2.png"),
    },
    {
      img: require("../assets/hero/hero3.png"),
    },
    {
      img: require("../assets/hero/hero4.png"),
    },
  ];

  return (
    <Swiper
      modules={[Autoplay, Navigation, EffectCreative]}
      grabCursor={true}
      // effect={"creative"}
      creativeEffect={{
        prev: {
          shadow: true,
          translate: ["-20%", 0, -400],
          opacity: 0,
        },
        next: {
          translate: ["100%", 0, 0],
        },
      }}
      loop={true}
      autoplay={{ delay: 3000, pauseOnMouseEnter: true }}
      speed={2000}
      slidesPerView={1}
      centeredSlides={true}
      spaceBetween={0}
      style={{
        "--swiper-theme-color": "#cfcfcf",
        "--swiper-pagination-bullet-inactive-color": "#cfcfcf",
        "--swiper-pagination-bullet-inactive-opacity": "0.7",
      }}
      className="h4"
    >
      <div>
        {thumbnail.map(({ i, img }) => (
          <SwiperSlide>
            <img
              key={i}
              className="w-full h-[100svh] object-cover object-center | md:h-[100svh]"
              src={img}
              alt={img}
            />
          </SwiperSlide>
        ))}
      </div>
    </Swiper>
  );
};
