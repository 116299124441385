const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <section className=" bg-[#fff] text-[#08221F] relative">
      <div className="flex flex-col items-center w-[100%] mx-auto py-5">
        {/* <hr className="hidden mb-6 h-[0.3px] max-w-[1020px] mx-auto w-full border-none bg-[#ababab88] | md:block" /> */}
        <p className="flex items-center text-center text-sm mx-auto">
          &copy; {year} All rights reserved by Chekwas Construction
        </p>
      </div>
    </section>
  );
};

export default Footer;
