import { useEffect, useState } from "react";

import aboutUsImg from "../assets/about/abt.jpg";
import visionUsImg from "../assets/about/viss.jpg";
import missionImg from "../assets/about/miss.jpg";
import objectivesImg from "../assets/about/obj.jpg";
import ceoImg from "../assets/about/ceoImg.jpg";

const Services = () => {
  const [about, setAbout] = useState(false);
  const [vision, setVision] = useState(false);
  const [mission, setMission] = useState(false);
  const [objectives, setObjectives] = useState(false);
  const [ourTeam, setOurTeam] = useState(false);

  const [moreAbout, setMoreAbout] = useState(false);
  const [moreVision, setMoreVision] = useState(false);
  const [moreMission, setMoreMission] = useState(false);
  const [moreCeo, setMoreCeo] = useState(false);

  const transitionBar = () => {
    window.scrollY > 120 ? setAbout(true) : setAbout(false);
    window.scrollY > 850 ? setVision(true) : setVision(false);
    window.scrollY > 1520 ? setMission(true) : setMission(false);
    window.scrollY > 2620 ? setObjectives(true) : setObjectives(false);
    window.scrollY > 3220 ? setOurTeam(true) : setOurTeam(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", transitionBar);
    // clean-up function
    return () => window.removeEventListener("scroll", transitionBar);
  }, []);

  return (
    <section id="about" className="bg-[#a27322] bg-opacity-5">
      <div className="bg-white">
        <div
          className={`bg-[#a27322] bg-opacity-5 transition-all duration-1000 delay-100 ease-in-out | md:delay-0 ${
            about == true
              ? "opacity-100 translate-y-0"
              : "opacity-0 translate-y-8 | d:opacity-100 d:translate-y-0"
          }`}
        >
          <div className="px-[5%] mx-auto flex flex-col items-center justify-center gap-12 pt-24 pb-16 | md:pt-32 md:gap-12 md:flex-row-reverse md:items-start | 2xl:px-[12.5%]">
            <div className="md:w-1/2">
              <div className="flex items-center mb-6">
                <hr className="h-[2px] w-4 mr-1 border-none bg-pryColor" />
                <p className="font-semibold text-pryColor">About us</p>
              </div>

              <p className="text-3xl mb-6 mx-auto font-medium">
                We are a big player in the realty construction industry
              </p>

              <div className="relative space-y-5">
                <p className={`space-y-5`}>
                  <span className={`block`}>
                    Chekwas Realty Construction Company specializes in a wide range of services.
                    Including residential, commercial, and industrial construction projects.
                  </span>

                  <span className={`block`}>
                    With a strong focus on quality, innovation, and customer satisfaction, we have
                    established a solid reputation for delivering exceptional results.
                  </span>
                </p>
                <p className={`space-y-5 ${moreAbout !== true && "line-clamp-3"}`}>
                  <span className={`block`}>
                    We have been in business for over a decade now and are based in Enugu state,
                    Nigeria. Our team of experienced professionals specializes in all aspects of
                    construction, from design and planning to execution and project management.
                  </span>

                  <span className={`block`}>
                    We are committed to delivering projects on time, within budget, and to the
                    highest quality standards. We also prioritize customer satisfaction and the
                    maintenance of healthy, thriving professional relationships.
                  </span>
                </p>

                {moreAbout !== true && <div className="about-topShadow-1" />}
              </div>

              <button
                onClick={() => setMoreAbout(!moreAbout)}
                className={`flex items-center bg-black text-[#f7f7f7] text-sm font-medium mt-6 px-3 py-3`}
              >
                {moreAbout == true ? "Show less" : "Read more"}
                <svg
                  className={`ml-1 h-[8px] transition duration-500 ${
                    moreAbout == true ? "rotate-180" : "rotate-0"
                  }`}
                  viewBox="0 0 30 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27 3L15 15L3 3"
                    stroke="#fff"
                    stroke-width="3"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>

            <img
              className="about__image w-full h-[220px] object-cover | md:w-1/2 md:h-[520px] md:mr-[5%]"
              src={aboutUsImg}
              alt="picture of our project"
            />
          </div>
        </div>

        <div
          className={`transition-all duration-1000 ease-in-out delay-1000 | md:delay-0 ${
            vision == true ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8 "
          }`}
        >
          <div className="px-[5%] mx-auto flex flex-col items-center justify-center gap-12 py-16 | md:gap-12 md:flex-row md:items-start | 2xl:px-[12.5%]">
            <div className="md:w-1/2">
              <div className="flex items-center mb-6">
                <hr className="h-[2px] w-4 mr-1 border-none bg-pryColor" />
                <p className="font-semibold text-pryColor">Our Vision</p>
              </div>
              <p className="text-3xl font-medium">
                We aim to build a legacy of excellence by exceeding standards in craftsmanship and
                professionalism
              </p>
              <div>
                <div className="relative">
                  <p className={`font- text- my-6 mb-3 ${moreVision == false && "line-clamp-6"}`}>
                    We envision a future where Chekwas Realty Construction Company is synonymous
                    with reliability, efficiency, and innovation in the construction industry.
                  </p>

                  <p className={`font- text- my-6 mb-3 ${moreVision == false && "line-clamp-3"}`}>
                    By embracing cutting-edge technologies, fostering a culture of continuous
                    improvement, and prioritizing sustainability in all our projects, we aim to set
                    new benchmarks for excellence and make a positive impact on the communities we
                    serve.
                  </p>
                  {moreVision == false && <div className="about-topShadow" />}
                </div>

                <button
                  onClick={() => setMoreVision(!moreVision)}
                  className={`flex items-center bg-black text-[#f7f7f7] text-sm font-medium mt-6 px-3 py-3`}
                >
                  {moreVision == true ? "Show less" : "Read more"}
                  <svg
                    className={`ml-1 h-[8px] transition duration-500 ${
                      moreVision == true ? "rotate-180" : "rotate-0"
                    }`}
                    viewBox="0 0 30 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M27 3L15 15L3 3"
                      stroke="#fff"
                      stroke-width="3"
                      stroke-linecap="square"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <img
              className="about__image w-full h-[320px] object-cover | md:w-1/2 md:h-[520px] md:ml-[5%]"
              src={missionImg}
              alt="Picture about our company"
            />
          </div>
        </div>

        <div
          className={`bg-[#a27322] bg-opacity-5 transition-all duration-1000 ease-in-out delay-1000 | md:delay-0 ${
            mission == true ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
          }`}
        >
          <div className="px-[5%] mx-auto flex flex-col items-center justify-center gap-12 py-16 | md:gap-12 md:flex-row-reverse md:items-start | 2xl:px-[12.5%]">
            <div className="md:w-1/2">
              <div className="flex items-center mb-4">
                <hr className="h-[2px] w-4 mr-1 border-none bg-pryColor" />
                <p className="font-semibold text-pryColor">Our Mission</p>
              </div>
              <p className="text-3xl font-medium">
                Our mission is to deliver high-quality construction services with integrity,
                professionalism, and excellence
              </p>
              <div>
                <div className="relative">
                  <p className={`font- my-6 mb-3 ${moreMission == false && "line-clamp-6"}`}>
                    We strive to exceed our clients' expectations by providing innovative solutions,
                    superior craftsmanship, and timely project delivery. Our goal is to build
                    long-lasting relationships based on trust, reliability, and exceptional
                    performance. We are dedicated to creating sustainable and safe environments
                    while upholding the highest standards of ethics and accountability in all our
                    endeavors.
                  </p>

                  {moreMission == false && <div className="about-topShadow-1" />}
                </div>

                <button
                  onClick={() => setMoreMission(!moreMission)}
                  className={`flex items-center bg-black text-[#f7f7f7] text-sm font-medium mt-6 px-3 py-3`}
                >
                  {moreMission == true ? "Show less" : "Read more"}
                  <svg
                    className={`ml-1 h-[8px] transition duration-500 ${
                      moreMission == true ? "rotate-180" : "rotate-0"
                    }`}
                    viewBox="0 0 30 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M27 3L15 15L3 3"
                      stroke="#fff"
                      stroke-width="3"
                      stroke-linecap="square"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <img
              className="about__image w-[100%] h-[420px] object-cover | md:w-1/2 md:h-[520px] md:mr-[5%]"
              src={visionUsImg}
              alt="Picture about our company"
            />
          </div>
        </div>

        <div
          className={`transition-all duration-1000 ease-in-out delay-1000 | md:delay-0 ${
            objectives == true ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
          }`}
        >
          <div className="px-[5%] mx-auto flex flex-col items-center justify-center gap-12 py-16 | md:gap-12 md:flex-row md:items-center | 2xl:px-[12.5%]">
            <div className="md:w-1/2">
              <div className="flex items-center mb-4">
                <hr className="h-[2px] w-4 mr-1 border-none bg-pryColor" />
                <p className="font-semibold text-pryColor">Our Objectives</p>
              </div>
              <p className="text-3xl font-medium">
                At Chekwas Realty Construction Company we prioritize:
              </p>
              <div className="mt-5">
                <ul className="objective__list pl-4">
                  <li>Customer Satisfaction</li>
                  <li>Improve operational efficiency</li>
                  <li>Ensure safety and compliance</li>
                  <li>Enhance sustainability</li>
                  <li>Enhance technology integration</li>
                </ul>
              </div>
            </div>

            <img
              className="about__image hidden w-[100%] h-[320px] object-cover | md:flex md:w-1/2 md:h-[520px] md:ml-[5%]"
              src={objectivesImg}
              alt="Picture about our company"
            />
          </div>
        </div>
      </div>

      <div
        className={`transition-all duration-1000 ease-in-out delay-1000 | md:delay-0 ${
          ourTeam == true ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
        }`}
      >
        <div className="px-[5%] py-12 space-y-8 | md:space-y-0 md:flex md:flex-row-reverse md:items-cente 2xl:px-[12.5%]">
          <div className="space-y-6 | md:space-y-8">
            <p className="text-[#a27322] text-2xl font-bold | md:text-4xl">Meet Our CEO</p>
            <p className="">
              <svg
                width="28"
                height="28"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.9999 12C12.2652 12 12.5195 11.8946 12.707 11.7071C12.8946 11.5196 12.9999 11.2652 12.9999 11V8.558C12.9999 8.29278 12.8946 8.03843 12.707 7.85089C12.5195 7.66336 12.2652 7.558 11.9999 7.558H10.6119C10.6119 7.20667 10.6326 6.85533 10.6739 6.504C10.7359 6.132 10.8393 5.80133 10.9839 5.512C11.1286 5.22267 11.3149 4.995 11.5429 4.829C11.7696 4.643 12.0589 4.55 12.4109 4.55V3C11.8323 3 11.3256 3.124 10.8909 3.372C10.4593 3.61687 10.0884 3.95595 9.80594 4.364C9.52155 4.81315 9.31215 5.30559 9.18594 5.822C9.05832 6.39174 8.99592 6.97415 8.99994 7.558V11C8.99994 11.2652 9.1053 11.5196 9.29283 11.7071C9.48037 11.8946 9.73472 12 9.99994 12H11.9999ZM5.99994 12C6.26515 12 6.51951 11.8946 6.70704 11.7071C6.89458 11.5196 6.99994 11.2652 6.99994 11V8.558C6.99994 8.29278 6.89458 8.03843 6.70704 7.85089C6.51951 7.66336 6.26515 7.558 5.99994 7.558H4.61194C4.61194 7.20667 4.6326 6.85533 4.67394 6.504C4.7366 6.132 4.83994 5.80133 4.98394 5.512C5.12861 5.22267 5.31494 4.995 5.54294 4.829C5.76961 4.643 6.05894 4.55 6.41094 4.55V3C5.83227 3 5.3256 3.124 4.89094 3.372C4.45928 3.61687 4.08841 3.95595 3.80594 4.364C3.52155 4.81315 3.31215 5.30559 3.18594 5.822C3.05832 6.39174 2.99592 6.97415 2.99994 7.558V11C2.99994 11.2652 3.10529 11.5196 3.29283 11.7071C3.48037 11.8946 3.73472 12 3.99994 12H5.99994Z"
                  fill="#08221F"
                />
              </svg>
              Explore with us, a journey through stunning architecture, where every building tells a
              story of creativity, eco-friendliness, and enduring beauty, enchanting both hearts and
              minds, transforming into living masterpieces. Let us bring your dream of owning
              real estate to life.
              <svg
                className="rotate-180"
                width="28"
                height="28"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.9999 12C12.2652 12 12.5195 11.8946 12.707 11.7071C12.8946 11.5196 12.9999 11.2652 12.9999 11V8.558C12.9999 8.29278 12.8946 8.03843 12.707 7.85089C12.5195 7.66336 12.2652 7.558 11.9999 7.558H10.6119C10.6119 7.20667 10.6326 6.85533 10.6739 6.504C10.7359 6.132 10.8393 5.80133 10.9839 5.512C11.1286 5.22267 11.3149 4.995 11.5429 4.829C11.7696 4.643 12.0589 4.55 12.4109 4.55V3C11.8323 3 11.3256 3.124 10.8909 3.372C10.4593 3.61687 10.0884 3.95595 9.80594 4.364C9.52155 4.81315 9.31215 5.30559 9.18594 5.822C9.05832 6.39174 8.99592 6.97415 8.99994 7.558V11C8.99994 11.2652 9.1053 11.5196 9.29283 11.7071C9.48037 11.8946 9.73472 12 9.99994 12H11.9999ZM5.99994 12C6.26515 12 6.51951 11.8946 6.70704 11.7071C6.89458 11.5196 6.99994 11.2652 6.99994 11V8.558C6.99994 8.29278 6.89458 8.03843 6.70704 7.85089C6.51951 7.66336 6.26515 7.558 5.99994 7.558H4.61194C4.61194 7.20667 4.6326 6.85533 4.67394 6.504C4.7366 6.132 4.83994 5.80133 4.98394 5.512C5.12861 5.22267 5.31494 4.995 5.54294 4.829C5.76961 4.643 6.05894 4.55 6.41094 4.55V3C5.83227 3 5.3256 3.124 4.89094 3.372C4.45928 3.61687 4.08841 3.95595 3.80594 4.364C3.52155 4.81315 3.31215 5.30559 3.18594 5.822C3.05832 6.39174 2.99592 6.97415 2.99994 7.558V11C2.99994 11.2652 3.10529 11.5196 3.29283 11.7071C3.48037 11.8946 3.73472 12 3.99994 12H5.99994Z"
                  fill="#08221F"
                />
              </svg>
            </p>
            <p className="font-bold text-xl hidden | md:block">
              Engr. Chukwujekwu Onwuchekwa
              <span className="block font-normal text-base">
                Visionary Leader and CEO of Chekwas Construction Realty{" "}
              </span>
            </p>

            <div className="hidden | md:block">
              <div className="relative">
                <p className={`space-y-4  ${moreCeo == false && "line-clamp-6"}`}>{ceoQuote}</p>

                {moreCeo == false && <div className="about-topShadow-1" />}
              </div>
              <button
                onClick={() => setMoreCeo(!moreCeo)}
                className={`flex items-center bg-black text-[#f7f7f7] text-sm font-medium mt-6 px-3 py-3`}
              >
                {moreCeo == true ? "Show less" : "Read more"}
                <svg
                  className={`ml-1 h-[8px] transition duration-500 ${
                    moreCeo == true ? "rotate-180" : "rotate-0"
                  }`}
                  viewBox="0 0 30 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27 3L15 15L3 3"
                    stroke="#fff"
                    stroke-width="3"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>

          <img
            className="about__image w-[100%] object-cover | md:flex md:w-1/2 md:h-[520px] md:mr-[5%]"
            src={ceoImg}
            alt="our CEO"
          />

          <p className="font-bold text-xl | md:hidden">
            Engr. Chukwujekwu Onwuchekwa
            <span className="block font-normal text-base">
              Visionary Leader and CEO of Chekwas Construction Realty
            </span>
          </p>

          <div className="md:hidden">
            <div className="relative">
              <p className={`space-y-4  ${moreCeo == false && "line-clamp-6"}`}>{ceoQuote}</p>

              {moreCeo == false && <div className="about-topShadow-1" />}
            </div>
            <button
              onClick={() => setMoreCeo(!moreCeo)}
              className={`flex items-center bg-black text-[#f7f7f7] text-sm font-medium mt-6 px-3 py-3`}
            >
              {moreCeo == true ? "Show less" : "Read more"}
              <svg
                className={`ml-1 h-[8px] transition duration-500 ${
                  moreCeo == true ? "rotate-180" : "rotate-0"
                }`}
                viewBox="0 0 30 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27 3L15 15L3 3"
                  stroke="#fff"
                  stroke-width="3"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* <p className="px-[5%] mb-2 font-bold text-lg text-[#a27322] | 2xl:px-[12.5%]">
          Our Management Team
        </p>
        <div className="flex items-center justify-center">
          <div className="w-full px-[5%] overflow-x-scroll scrollbar-hide | 2xl:px-[12.5%]">
            <div className="flex gap-x-4 w-[600px] mb-8">
              {mgtStaff.map(({ imgUrl, name, position }, i) => (
                <div className="w-[360px]" key={i}>
                  <img className="w-[360px] h-[280px] object-cover" src={imgUrl} alt={name} />
                  <p className="font-bold text-lg">{name}</p>
                  <p className="">{position}</p>
                </div>
              ))}
            </div>
          </div>
          <img
            className="hidden h-[380px] w-[100%] ml-[2.5%] mr-[5%] -translate-y-10 object-cover | md | xl:ml-[10px] lg:block"
            src={require("../assets/footer-img.png")}
            alt="an image of a building"
          />
        </div> */}
      </div>
    </section>
  );
};

export default Services;

export const mgtStaff = [
  // {
  //   imgUrl: require("../assets/about/ceoImg.jpg"),
  //   name: "Engr. Chukwujekwu Onwuchekwa",
  //   position: "CEO",
  // },
  {
    imgUrl: require("../assets/about/exe_admin.jpg"),
    name: "Uzochukwu Adaeze Angela",
    position: "Executive Administrator",
  },
  {
    imgUrl: require("../assets/about/marketing_Specialist.jpg"),
    name: "Eunice Amarachi Ani-Edeh",
    position: "Marketing Specialist/Client Relationship Officer",
  },
];

export const ceoQuote = (
  <>
    <span className="block font-normal text-base">
      Engr. Chukwujekwu Onwuchekwa is the driving force behind Chekwas Construction Realty, a
      premier luxury real estate development company. With a career spanning several years, he has
      demonstrated exceptional leadership, innovative thinking, and a commitment to excellence,
      propelling the company to new heights.
    </span>
    <span className="block font-normal text-base">
      A distinguished alumnus of the Federal University of Technology Owerri, Engr. Onwuchekwa
      earned his degree in Civil Engineering. He joined his family's construction company,
      transforming it into a comprehensive construction realty firm.
    </span>
    <span className="block font-normal text-base">
      His visionary leadership and dedication to innovation have led to numerous high-end
      residential and commercial projects characterized by meticulous attention to detail and
      sustainable practices.
    </span>
    <span className="block font-normal text-base">
      Beyond his professional achievements, Engr. Onwuchekwa is committed to community development
      and philanthropy, supporting initiatives in infrastructure, education, and housing.
    </span>
    <span className="block font-normal text-base">
      His blend of technical expertise, strategic vision, and dedication to quality has established
      Chekwas Construction Realty as a leader in the luxury real estate market.
    </span>
  </>
);
